import React, { memo, useMemo, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './SightseeingTopic.css';
import { fetchCountryIso } from  './TravelTopics';
import { useParams } from 'react-router-dom';
import { enableScroll } from './Scroll';
import {useMap, MapContainer, TileLayer, Marker } from 'react-leaflet';
import { Helmet } from 'react-helmet';
import 'leaflet/dist/leaflet.css'; 
import L from 'leaflet';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const UpdateMapView = ({ center }) => {
  const map = useMap();
  useEffect(() => {
    map.setView(center);
  }, [center, map]);
  return null;
};


export async function fetchSightseeing(iso) {
  try {
    const response = await axios.get(`${backendUrl}/worlddata/sightseeing/get-sightseeing-data/${iso}`);
    console.log('Sightseeing Results:', response.data); // Log to verify response data
    return response.data; // Return the fetched data
  } catch (error) {
    console.error('Error fetching sightseeing data:', error);
    throw error; // Propagate the error if needed
  }
}

export async function fetchRandomSightseeing(iso) {
  try {
    const response = await axios.get(`${backendUrl}/worlddata/sightseeing/get-random-sightseeing-data/`);
    console.log('Sightseeing Results:', response.data); // Log to verify response data
    return response.data; // Return the fetched data
  } catch (error) {
    console.error('Error fetching sightseeing data:', error);
    throw error; // Propagate the error if needed
  }
}

export function fetchNumberOfSightseeingPosts(iso) {
  // Determine the URL based on whether 'iso' is provided
  const url = iso
    ? `${backendUrl}/worlddata/sightseeing/get-sightseeing-counter/${iso}`
    : `${backendUrl}/worlddata/sightseeing/get-sightseeing-total-counter/`;

  // Make the API request and return the number of posts
  return axios
    .get(url)
    .then(response => {
      console.log('Number of Sightseeing Posts:', response.data);
      return response.data.sightseeing_counter || response.data.sightseeing_counter_total; // Return the number of posts
    })
    .catch(error => {
      console.error('Error fetching sightseeing posts:', error);
      return 0; // Return 0 if there's an error
    });
}

export function fetchNumberOfSightseeingPostsPerContinent() {
  // Determine the URL based on whether 'iso' is provided
  const url = `${backendUrl}/worlddata/sightseeing/get-sightseeing-continent-counter/`;

  // Make the API request and return the number of posts
  return axios
    .get(url)
    .then(response => {
      console.log('Number of Sightseeing Posts Per Continent:', response.data);
      return response.data.sightseeing_counter_continent; // Return the number of posts
    })
    .catch(error => {
      console.error('Error fetching sightseeing posts:', error);
      return 0; // Return 0 if there's an error
    });
}

  const parseCoordinates = (coordinateString) => 
    { 
      const coordinatePattern = /(\d+)°(\d+)'(\d+\.\d+)"([NS])\s*[-\s]*([\-\d]+)°(\d+)'(\d+\.\d+)"([EW])/;
      const match = coordinateString.match(coordinatePattern); 
      if (!match) 
      {
          console.error('Invalid coordinate string:', coordinateString);
          return [NaN, NaN]; 
      }
      const [,latDeg, latMin, latSec, latDir, lonDeg, lonMin, lonSec, lonDir] = match; 
      
      const lat = (parseFloat(latDeg) + parseFloat(latMin) / 60 + parseFloat(latSec) / 3600) * (latDir === 'N' ? 1 : -1); 
      const lon = (parseFloat(lonDeg) + parseFloat(lonMin) / 60 + parseFloat(lonSec) / 3600) * (lonDir === 'E' ? 1 : -1); 
      return [lat, lon]; 
    };

const SightseeingTopic = () => {
  const [sightseeingData, setSightseeingData] = useState([]); // State to store search results from the database
  const { country } = useParams(); // Extract the country from the URL
  const arrayContainerRef = useRef(null); // Create a ref for the .array-container div
  const [mapCenter, setMapCenter] = useState([0, 0]);
  const itemRefs = useRef([]); // Ref for each item
  const [activePost, setActivePost] = useState(0); // Track the active post
  const [activeMarker, setActiveMarker] = useState(0); // State to store active marker index
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const descriptionRef = useRef(null);


  const handleItemClick = (post, index, scrollToDescription = false) => {
    const [lat, lon] = parseCoordinates(post.coordinates);
    setActivePost(index); // Set the active post
    if (!isNaN(lat) && !isNaN(lon)) {
      setMapCenter([lat, lon]);
      setActiveMarker(index);
    
      // Scroll the clicked item into view
    itemRefs.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });

    if (scrollToDescription) {
      descriptionRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }

    }
  };

  const MemoizedMarker = memo(({ position, icon, onClick }) => {
    const eventHandlers = useMemo(
      () => ({
        click: onClick,
      }),
      [onClick]
    );
    return <Marker position={position} icon={icon} eventHandlers={eventHandlers} />
    
  });

  const openLightbox = (image) => {
    setSelectedImage(image);
    setLightboxOpen(true);
  };
  
  const closeLightbox = () => {
    setSelectedImage('');
    setLightboxOpen(false);
  };

  
  const handleMarkerItemClick = (index) => {
    // Set the clicked marker as active
    setActiveMarker(index);
    setActivePost(index); // Set the active post

    // Scroll the clicked item into view
    itemRefs.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  const createCustomIcon = (isActive) =>
    L.divIcon({
      className: `custom-marker ${isActive ? 'active' : ''}`, // Add active class when active
      iconSize: [30, 30],
      iconAnchor: [15, 30], // Anchor point at the bottom center
    });
  
  useEffect(() => {
    const arrayContainer = arrayContainerRef.current;

    // Call enableScroll and get the cleanup function
    const cleanupScroll = enableScroll(arrayContainer);

    // Cleanup scroll event listeners on component unmount
    return cleanupScroll;
  }, []); // Only runs once after the initial render

  useEffect(() => {
  const fetchSightseeing=(iso)=>
    {
      axios
      .get(`${backendUrl}/worlddata/sightseeing/get-sightseeing-data/${iso}`)
      .then(response => {
        console.log('Sightseeing Results:', response.data); // Log to verify response data
        setSightseeingData(response.data); // Update the search results with matching countries
        if (response.data.length > 0) 
          { 
            const [firstPost] = response.data; 
            const [lat, lon] = parseCoordinates(firstPost.coordinates);
           setMapCenter([parseFloat(lat), parseFloat(lon)]); 
           console.log('firstPost=',firstPost);
           console.log('lat=',lat);
           console.log('lon=',lon);
          }
      })
      .catch(error => console.error('Error searching countries', error));
    }
    fetchCountryIso(country)
     .then(iso => {
    console.log('ISO code:', iso);
    fetchSightseeing(iso); // Call the function when the component mounts or the country changes
     })
    .catch(error => {
    console.error('Error fetching country iso:', error);
    });
  }, [country]); // Dependency array includes 'country'

  return (
    <div className='SightseeingPage'>

    <Helmet>
      <title>Travel Spots for {country} - LivingOutOfABackpack™</title>
      <meta name="robots" content="index, follow" />
      <meta name="description" content={`Find travel spots for ${country}.`}/>
      <meta name="keywords" content={`Travel spots for ${country},${country}, ONESCO ${country}`}/>
      <meta
      name="keywords"
      content={`${country} travel spots, 
                sightseeing in ${country},
                top attractions in ${country},
                best places to visit in ${country}, 
                travel guide ${country}, 
                sightseeing tours ${country}, 
                famous landmarks ${country}, 
                vacation spots ${country}, 
                things to do in ${country}`}
      />
      <meta property="og:title" content="Travel spots - LivingOutOfABackpack™" />
      <meta property="og:description" content={`Explore top travel spots and activities in ${country}.`} />
    </Helmet>

      <h1>Travel spots for {country}</h1>
      <h2>Experience all that {country} has to offer.</h2>
      <div className="array-container"ref={arrayContainerRef}>
          {Array.isArray(sightseeingData) && sightseeingData.length > 0 ? (
          sightseeingData.map((post, index) => (
            <div key={index} className={`array-item ${activePost === index ? 'active' : ''}`} onClick={() => handleItemClick(post, index)}  ref={(el) => (itemRefs.current[index] = el)}>
              <div className="picture-content">
                <img
                  src={`${backendUrl}/django-dynamic/pictures/${post.images.at(0)}`}
                  title={post.place}
                  alt={post.place}
                  onClick={() => openLightbox(post.images.at(0))}
                />
              </div>
              <div className="block-title">{post.place} {post.activity}</div>

              <div className='quick-select'>
                {post.coordinates && (
                  <div className="coordinates">{post.coordinates}</div>
                )}

                <button 
                  className="description-button" 
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering the array-item click
                    handleItemClick(post, index, true); // Scroll to the description
                  }}>
                  Description
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>travel spots and activities.</p>
        )}
        </div>
          <div className='PostWiki'>

        <div className="LeafletContainer">
          <MapContainer center={mapCenter} zoom={7} style={{ height: '300px', width: '300px' }} >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <UpdateMapView center={mapCenter} />
            {sightseeingData.map((post, index) => { 
              const [lat, lon] = parseCoordinates(post.coordinates); 
              if (!isNaN(lat) && !isNaN(lon)) {
                return (
                  <MemoizedMarker 
                  key={index} 
                  position={[lat, lon]} 
                  icon={createCustomIcon(activeMarker === index)} 
                  onClick={() => handleMarkerItemClick(index)}/>
                );
              }
              return null;
            })}
          </MapContainer>
          </div>

          <div className='Description' ref={descriptionRef}>
            <div className='DescriptionTitleBanner'>
              <h1>{sightseeingData.length > 0 && sightseeingData[activePost] ? sightseeingData[activePost].place || sightseeingData[activePost].activity: 'Place name.'}</h1>
              Description:
            </div>
            <div className='DescriptionText'>
              {sightseeingData.length > 0 && sightseeingData[activePost] ? sightseeingData[activePost].description : 'The travel spot description.'}
            </div>
          </div>

            {sightseeingData.length > 0 &&
            sightseeingData[activePost] &&
            sightseeingData[activePost].images ? (
              sightseeingData[activePost].images.slice(1).map((image, index) => (
                <div className='ExtraPictures'>
                  <div className="picture-content">
                    <img
                      key={index}
                      src={`${backendUrl}/django-dynamic/pictures/${image}`}
                      title={`${sightseeingData[activePost].place} - Extra Picture ${index + 1}`}
                      alt={`${sightseeingData[activePost].place} - Extra Picture ${index + 1}`}
                      onClick={() => openLightbox(image)}
                    />
                  </div>
                </div>
              ))
            ) : null }
          
          
        </div>
        {lightboxOpen && (
          <div className="lightbox" onClick={closeLightbox}>
            <img src={`${backendUrl}/django-dynamic/pictures/${selectedImage}`} alt="Enlarged view" />
            <button className="close-button" onClick={closeLightbox}>X</button>
          </div>
        )}
    </div>
  );
};

export default SightseeingTopic;