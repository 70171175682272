

function DisclaimerAndPolicyPage() {
  return (
    <div>
        <h1>Terms and Policies</h1>
      <h2>Disclaimer – Information Accuracy</h2>
      <p>
      The information provided on this website about restaurants, places,
     and sightseeing attractions is based on the most recent data at the time of publication.
    However, due to the dynamic nature of the hospitality and tourism industries,
     details such as menus, prices, operating hours, and availability may change without notice.
     </p>

      <p>
    We are committed to regularly review and update content wherever possible to provide the best experience possible. 
    </p>
    
    <p>
    We recommend that readers verify key details directly with the respective businesses or locations before planning their visits.
    The website assumes no responsibility for inaccuracies or inconveniences resulting from outdated information.
      </p>

    <h2>Cookies Policy</h2>
    <p>
    Our website uses only essential cookies to ensure its proper functionality. These cookies are necessary for the website to work, and they cannot be disabled. 
    They include session cookies, which help maintain your session as you navigate the site, and CSRF cookies, which protect against cross-site request forgery attacks. 
    </p>
    </div>

  /*
  return (
    <div>
        <h1>Terms and Policies</h1>
      <h2>Disclaimer – Information Accuracy</h2>
      <p>
      The information provided on this website about restaurants, places,
     and sightseeing attractions is based on the most recent data at the time of publication.
    However, due to the dynamic nature of the hospitality and tourism industries,
     details such as menus, prices, operating hours, and availability may change without notice.

    We are committed to regularly review and update content wherever possible to provide the best experience possible. 

    We recommend that readers verify key details directly with the respective businesses or locations before planning their visits.
    The website assumes no responsibility for inaccuracies or inconveniences resulting from outdated information.
      </p>
    
    <h2>Privacy Policy for User Information Collection (Last updated 28-12-2024)</h2>
    <p>
       At [Your Website Name],
        we value your privacy and are committed to protecting your personal information.
         This Privacy Policy explains how we collect, use, and safeguard the data you provide when creating an account
          on our website and participating in our community discussions. 
    </p>

    <p>
        1. Information We Collect
        When you create an account on [Your Website Name], we may collect the following information:

        Personal Information: Your email address and username.
        Optional Information: Profile picture, bio, or other details you choose to share.
        Automatically Collected Data: IP address, browser type, and usage data (to improve the website experience).
    </p>

    <p>
        2. How We Use Your Information
        We use your information for the following purposes:

        To create and manage your account.
        To facilitate discussions in the community tab and improve user engagement.
        To improve our website functionality and features.
    </p>

    <p>
        3. Sharing Your Information
        We respect your privacy and do not sell, trade, or share your personal information with third parties.
    </p>

    <p>
        4. Your Choices and Rights
        You have the following rights regarding your personal information:

        Access and Update: You can view and edit your personal information in your account settings.
        Account Deletion: You may delete your account by going to the account settings.
    </p>

    <p>
        5. Changes to This Policy
        We may update this Privacy Policy from time to time. Any changes will be posted on this page with a revised "Last Updated" date.
    </p>

    <p>
        6. Contact Us
        If you have questions or concerns about this Privacy Policy, please contact us at [Your Email Address].
    </p>
</div>
  */
  );

};

export default DisclaimerAndPolicyPage;