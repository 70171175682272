import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ZoomableGroup,ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { fetchNumberOfSightseeingPosts, fetchNumberOfSightseeingPostsPerContinent } from  './SightseeingTopic';
import { fetchNumberOfFoodPosts, fetchNumberOfFoodPostsPerContinent } from  './FoodTopic';
import {ResponsiveContainer, PieChart, Pie, Tooltip, Legend } from 'recharts';
import { Helmet } from 'react-helmet';
import './WorldMap.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const geoJson = `${backendUrl}/django-static/world-110m.json`;

function WorldMap() {
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [GetModalOpen, setIsModalOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState(''); // State to store the search input
  const [searchResults, setSearchResults] = useState([]); // State to store search results from the database

  const [flagUrl, setFlagUrl] = useState(null); // State to store the flag URL

  const [countryNumSightseeingPosts, setCountryNumSightseeingPosts] = useState(null); // Number of sightseeing posts
  const [totalNumSightseeingPosts, setTotalNumSightseeingPosts] = useState(null); // Number of sightseeing posts
  const [continentNumSightseeingPosts, setContinetNumSightseeingPosts] = useState(null); // Number of sightseeing posts

  const [countryNumFoodPosts, setCountryNumFoodPosts] = useState(null); // Number of food posts
  const [totalNumFoodPosts, setTotalNumFoodPosts] = useState(null); // Number of sightseeing posts
  const [continentNumFoodPosts, setContinetNumFoodPosts] = useState(null); // Number of sightseeing posts


  const pieSightseeingData = continentNumSightseeingPosts
  ? [
      { name: 'Europe', value: continentNumSightseeingPosts["Europe"], fill: '#ffc658' },
      { name: 'Africa', value: continentNumSightseeingPosts["Africa"], fill: '#ff8c42' },
      { name: 'Asia', value: continentNumSightseeingPosts["Asia"], fill: '#ff4c4c' },
      { name: 'North America', value: continentNumSightseeingPosts["North America"], fill: '#a4de6c' },
      { name: 'South America', value: continentNumSightseeingPosts["South America"], fill: '#8884d8' },
      { name: 'Oceania & Antarctica', value: (continentNumSightseeingPosts["Oceania"] || 0) + (continentNumSightseeingPosts["Antarctica"] || 0), fill: '#8dd1e1' }
    ]
  : [];

  const pieFoodData = continentNumFoodPosts
  ? [
      { name: 'Europe', value: continentNumFoodPosts["Europe"], fill: '#ffc658' },
      { name: 'Africa', value: continentNumFoodPosts["Africa"], fill: '#ff8c42' },
      { name: 'Asia', value: continentNumFoodPosts["Asia"], fill: '#ff4c4c' },
      { name: 'North America', value: continentNumFoodPosts["North America"], fill: '#a4de6c' },
      { name: 'South America', value: continentNumFoodPosts["South America"], fill: '#8884d8' },
      { name: 'Oceania & Antarctica', value: (continentNumFoodPosts["Oceania"] || 0) + (continentNumFoodPosts["Antarctica"] || 0), fill: '#8dd1e1' }
    ]
  : [];

    useEffect(() => {
      fetchNumberOfSightseeingPosts()// No parameter because fetch all
        .then(numPosts => {setTotalNumSightseeingPosts(numPosts)
        })
        
        .catch(error => {
          console.error('Error fetching sightseeing posts:', error);
          setTotalNumSightseeingPosts(null);
        });
    }, []); // Empty dependency array ensures this runs only once

    useEffect(() => {
      fetchNumberOfSightseeingPostsPerContinent()// No parameter because fetch all
        .then(numPosts => {setContinetNumSightseeingPosts(numPosts)
        })
        
        .catch(error => {
          console.error('Error fetching sightseeing posts:', error);
          setContinetNumSightseeingPosts(null);
        });
    }, []); // Empty dependency array ensures this runs only once

    useEffect(() => {
      fetchNumberOfFoodPosts()// No parameter because fetch all
        .then(numPosts => {setTotalNumFoodPosts(numPosts)
        })
        
        .catch(error => {
          console.error('Error fetching food posts:', error);
          setTotalNumFoodPosts(null);
        });
    }, []); // Empty dependency array ensures this runs only once

    useEffect(() => {
      fetchNumberOfFoodPostsPerContinent()// No parameter because fetch all
        .then(numPosts => {setContinetNumFoodPosts(numPosts)
        })
        
        .catch(error => {
          console.error('Error fetching food posts:', error);
          setContinetNumFoodPosts(null);
        });
    }, []); // Empty dependency array ensures this runs only once

  // Function to fetch country details
  const handleClick = geo => {
    const countryName = geo.properties.name; // Adjust based on actual field

    if (!countryName) {
      console.error('Country name is undefined!');
      return;
    }

    axios
      .get(`${backendUrl}/worlddata/countries/${countryName}`)
      .then(response => {
        setSelectedCountry(response.data);

        const countryIso = response.data.iso.toLowerCase(); // Ensure ISO code is lowercase for the URL
        setFlagUrl(`https://flagcdn.com/w320/${countryIso}.png`);

        // Fetch the number of sightseeing posts asynchronously
        fetchNumberOfSightseeingPosts(response.data.iso)
        .then(numPosts => setCountryNumSightseeingPosts(numPosts))
        .catch(error => {
          console.error('Error fetching sightseeing posts:', error);
          setCountryNumSightseeingPosts(null);
        });

        // Fetch the number of food posts asynchronously
        fetchNumberOfFoodPosts(response.data.iso)
        .then(numPosts => setCountryNumFoodPosts(numPosts))
        .catch(error => {
          console.error('Error fetching food posts:', error);
          setCountryNumFoodPosts(null);
        });

        setIsModalOpen(true); // Show the modal after fetching country data
      })
      .catch(error => console.error('Error fetching country data', error));
  };

  // Function to handle country selection from the search results
  const handleSearchClick = countryName => {
    axios
      .get(`${backendUrl}/worlddata/countries/${countryName}`)
      .then(response => {
        setSelectedCountry(response.data);
        const countryIso = response.data.iso.toLowerCase();
        setFlagUrl(`https://flagcdn.com/w320/${countryIso}.png`);
        setIsModalOpen(true);

        // Clear the search query after a selection
        setSearchQuery('');
      })
      .catch(error =>
        console.error('Error fetching country data from search', error)
      );
  };

  // Function to handle the search query change
  const handleSearchChange = event => {
    const query = event.target.value;
    setSearchQuery(query);

    // If search query is not empty, make an API call to search countries by name
    if (query.length > 0) {
      axios
        .get(`${backendUrl}/worlddata/countries?name=${query}`)
        .then(response => {
          console.log('Search Results:', response.data); // Log to verify response data
          setSearchResults(response.data); // Update the search results with matching countries
        })
        .catch(error => console.error('Error searching countries', error));
    } else {
      setSearchResults([]); // Clear search results when search query is empty
    }
  };

  // Close modal handler
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCountry(null); // Optional: Clear selected country on close
    setFlagUrl(null); // Optional: Clear flag URL on close\
    setCountryNumSightseeingPosts(null); // Clear the sightseeing post count
    setCountryNumFoodPosts(null); // Clear the sightseeing post count
  };
  const isMobile = window.innerWidth <= 600; // Detect mobile
  return (
    <div className="WorldMapPage">
          <Helmet>
            <title>World Map - LivingOutOfABackpack™</title>
            <meta name="robots" content="index, follow" />
            <meta property="og:title" content="World Map - LivingOutOfABackpack™" />
            <meta property="og:description" content={`Explore the world with the help of our tools.`} />
          </Helmet>
      <div className="MapContainer">
        {/* Search and Results Container */}
        <div className="SearchBarContainer">
          <div
            style={{
              position: 'relative',
              width: '100%',
              background: 'white',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              borderRadius: '4px',
              zIndex: '1000',
            }}
          >
            {/* Search Bar */}
            <input className='SearchBarInput' type="text" placeholder="Search for a country" value={searchQuery} onChange={handleSearchChange}/>
            {/* Search Results */}
            {searchQuery.length > 0 && (
              <div className='SearchBarResults'>
                <ul className='SearchBarResultsList'>
                  {searchResults.length > 0 ? (
                    searchResults.map(country => (
                      <li className='SearchBarResultsItem' key={country.iso} onClick={() => handleSearchClick(country.name)}>
                        {country.name}
                      </li>
                    ))
                  ) : (
                    <li style={{ textAlign: 'center', padding: '8px' }}>
                      No matching countries found
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      

        {/* Map */}
        <ComposableMap >
          <ZoomableGroup
            center={isMobile ? [10, 40] : [0, 0]}
            zoom={isMobile ? 6 : 1} // Zoom in on mobile
            minZoom={1} 
            maxZoom={6} 
            translateExtent={[
              [0, -50], // Minimum X (-1000), Minimum Y (-500)
              [900, 700],   // Maximum X (1000), Maximum Y (500)
            ]} // Restrict panning
          >
          <Geographies geography={geoJson}>
            {({ geographies }) =>
              geographies.map(geo => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onClick={() => handleClick(geo)}
                  style={{
                    default: { 
                      fill: '#4CAF50', 
                      outline: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 0.5,
                    },
                    hover: { 
                      fill: '#ffc658', 
                      outline: 'none', // Remove outline
                      stroke: '#FFFFFF', // Add a white stroke to outline the country
                      strokeWidth: 2, // Set the width of the stroke (outline)
                      zIndex: 1000,
                    },
                    pressed: { 
                      fill: '#e6a02f', 
                      outline: 'none',
                      stroke: '#FFFFFF', // Add a white stroke when pressed
                      strokeWidth: 2, // Set the width of the pressed stroke
                    },
                  }}
                />
              ))
            }
          </Geographies>
          </ZoomableGroup>
        </ComposableMap>

        {/* Modal Overlay */}
        {GetModalOpen && selectedCountry && (
          <div className='ModalOverlay'onClick={closeModal}>
            <div className='MainOverlay'
              onClick={e => e.stopPropagation()} // Prevent click from closing modal when clicking inside
            >
              <button className='OverlayCloseButton'
                onClick={closeModal}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  background: 'transparent',
                  border: 'none',
                  fontSize: '250%',
                  cursor: 'pointer',
                }}
              >
                &times;
              </button>

              <div className='OverlayInfo'>
                <div className='OverlayTitle'>
                  <h2 style={{ color: 'white' }}>{selectedCountry.name}</h2>
                </div>
                <p style={{ color: 'black' }}>
                  <strong>Capital:</strong> {selectedCountry.capital}
                </p>
                <p style={{ color: 'black' }}>
                  <strong>ISO:</strong> {selectedCountry.iso}
                </p>


                {/* Display Flag */}
                {flagUrl && (
                  <div style={{ marginTop: '20px' }}>
                    <img
                      src={flagUrl}
                      alt={`${selectedCountry.name} Flag`}
                      style={{
                        width: '100%',
                        maxWidth: '150px',
                        height: 'auto',
                        borderRadius: '4px',
                      }}
                    />
                  </div>
                )}
              </div>
              
              <div className='OverlayMenu'>
                <div className='OverlaySightseeingTopic'>
                  <li className='ButtonTopicSightseeing' onClick={() => navigate(`/traveltopics/${selectedCountry.name}/topics/sightseeing`)}>
                  <text style={{fontSize: '20px'}}>{countryNumSightseeingPosts !== null ? countryNumSightseeingPosts : 'Loading...'} </text>
                  <text style={{fontSize: '20px'}}>Travel Spots</text>
                  <span className="material-icons icon" style={{fontSize: '30px'}}>{'hiking'}</span>
                  </li>
                </div>

                <div className='OverlayFoodTopic'>
                  <li className='ButtonTopicFood' onClick={() => navigate(`/traveltopics/${selectedCountry.name}/topics/food`)}>
                  <text style={{fontSize: '20px'}}>{countryNumFoodPosts !== null ? countryNumFoodPosts : 'Loading...'}</text>
                  <text style={{fontSize: '20px'}}>Food Posts</text>
                  <span className="material-icons icon" style={{fontSize: '30px'}}>{'restaurant_menu'}</span>
                  </li>
                </div>

                <div className='OverlayStatisticsTopic'>
                  <li className='ButtonTopicStatistics' onClick={() => navigate(`/traveltopics/${selectedCountry.name}/topics/statistics`)}>
                  <text style={{fontSize: '20px'}}>Statistics</text>
                  <span className="material-icons icon" style={{fontSize: '30px'}}>{'attach_money'}</span>
                  </li>
                </div>
              </div>

            </div>
          </div>
        )}
      </div>

      <div className='Counters'>
        <div className='SightseeingCounter'>
        <text className="ChartTitle"> Travel Spots
        <span className="material-icons icon">{'hiking'}</span>
        </text>
              {totalNumSightseeingPosts !== null ? (
                <div className='SightseeingChart'>
                 <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={pieSightseeingData}
                        cx="50%" /* Centered horizontally */
                        cy="55%" /* Centered vertically */
                        innerRadius="70%"
                        outerRadius="100%"
                        paddingAngle={6}
                        dataKey="value"
                        fill="#8884d8"
                      />
                      {/* Total Number in the Center */}
                      <text className='InnerCircleNumber'
                        x="50%"
                        y="20%"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fontSize="30"
                        fill="#333"
                      >
                        {totalNumSightseeingPosts}
                      </text>
                      <Tooltip />
                      <Legend width="100%" height="65%" />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                'Loading...'
              )}
        </div>
        <div className='FoodCounter'>
        <text className="ChartTitle"> Food posts
        <span className="material-icons icon">{'restaurant_menu'}</span>
        </text>
              {totalNumFoodPosts !== null ? (
                <div className='FoodChart'>
                 <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={pieFoodData}
                        cx="50%" /* Centered horizontally */
                        cy="55%" /* Centered vertically */
                        innerRadius="70%"
                        outerRadius="100%"
                        paddingAngle={6}
                        dataKey="value"
                        fill="#8884d8"
                      />
                      {/* Total Number in the Center */}
                      <text className='InnerCircleNumber'
                        x="50%"
                        y="20%"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fontSize="30"
                        fill="#333"
                      >
                        {totalNumFoodPosts}
                      </text>
                      <Tooltip />
                      <Legend width="100%" height="65%" />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                'Loading...'
              )}
        </div>
      </div>

    </div>
  );
}

export default WorldMap;
