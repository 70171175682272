import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './StatisticsTopic.css';
import { fetchCountryIso } from  './TravelTopics';
import { useParams } from 'react-router-dom';
import {CoinScene} from './SpinningGlobe';
import { Helmet } from 'react-helmet';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

let isFetching = false;  // Flag to check if a fetch operation is ongoing
let fetchQueue = []; // Queue to hold fetch functions

// Function to add fetch requests to the queue
function addToFetchQueue(fetchFunction) {
  fetchQueue.push(fetchFunction);

  // If there's no fetch in progress, process the queue
  if (!isFetching) {
    processFetchQueue();
  }
}
// Function to process fetch requests in the queue
async function processFetchQueue() {
  // Continue processing until the queue is empty
  while (fetchQueue.length > 0) {
    isFetching = true;

    // Get the next fetch function in the queue
    const fetchFunction = fetchQueue.shift();

    try {
      // Execute the fetch function
      await fetchFunction();
    } catch (error) {
      console.error("Error during processFetchQueue:", error);
    }

    isFetching = false;
  }
}


async function fetchCurrencyCacheData() {
  try {
    // Using axios to fetch currency data
    const response = await axios.get(`${backendUrl}/worlddata/currency/get-currency-cache/`);
    console.log(response.data); // You can log this or use it if necessary
  } catch (error) {
    console.error("Error fetching currency cache data:", error);
  }
}

async function fetchGdpCacheData() {
  try {
    // Using axios to fetch gdp data
    const response = await axios.get(`${backendUrl}/worlddata/gdp/get-gdp-cache/`);
    console.log(response.data); // You can log this or use it if necessary
  } catch (error) {
    console.error("Error fetching gdp cache data:", error);
  }
}

async function fetchPopulationCacheData() {
  try {
    // Using axios to fetch population data
    const response = await axios.get(`${backendUrl}/worlddata/population/get-population-cache/`);
    console.log(response.data); // You can log this or use it if necessary
  } catch (error) {
    console.error("Error fetching population cache data:", error);
  }
}

// Sequentially call the functions
async function fetchAllCacheData() {
  addToFetchQueue(fetchCurrencyCacheData);  // First, fetch currency data
  addToFetchQueue(fetchGdpCacheData);       // After currency data, fetch GDP data
  addToFetchQueue(fetchPopulationCacheData); // Finally, fetch population data
}

const StatisticsTopic = () => {
  const { country } = useParams(); // Extract the country from the URL
  const [flagUrl, setFlagUrl] = useState(''); // State for the flag URL
  const [hasExchangeRateToEur, setExchangeRateToEur] = useState('');
  const [hasGdp, setGdp] = useState('');
  const [hasPopulation, setPopulation] = useState('');
  const [statisticsData, setStatisticsData] = useState([]); // State to store search results from the database


  useEffect(() => {
    const fetchStatistics=(iso)=>
      {
        axios
        .get(`${backendUrl}/worlddata/statistics/get-statistics-data/${iso}`)
        .then(response => {
          console.log('Statistics Results:', response.data); // Log to verify response data
          setStatisticsData(response.data); // Update the search results with matching countries
        })
        .catch(error => console.error('Error searching countries', error));
      }
      fetchCountryIso(country)
       .then(iso => {
      fetchStatistics(iso); // Call the function when the component mounts or the country changes
      setFlagUrl(`https://flagcdn.com/w320/${iso.toLowerCase()}.png`);
      fetchAllCacheData();
       })
      .catch(error => {
      console.error('Error fetching country iso:', error);
      });
    }, [country]); // Dependency array includes 'country'

    useEffect(() => {
      // Retry logic for updating state based on statistics data
      if (statisticsData.length > 0) {
        console.log('Updated statisticsData:', statisticsData);
        if (statisticsData[0].exchange_rate_to_eur !== 'None') {
          setExchangeRateToEur(true);
        } else {
          setExchangeRateToEur(false);
        }

        if (statisticsData[0].gdp !== 'None') {
          setGdp(true);
        } else {
          setGdp(false);
        }

        if (statisticsData[0].population !== 'None') {
          setPopulation(true);
        } else {
          setPopulation(false);
        }
      }
    }, [statisticsData]);

  return (
    <div>
      <div className="StatisticsPage">
        <Helmet>
          <title>Statistics: {country} - LivingOutOfABackpack™</title>
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="Statistics Page - LivingOutOfABackpack™" />
          <meta property="og:description" content={`Explore the world with the help of our tool.`} />
        </Helmet>

        <h1>Statistics: {country}</h1>

        <div className='StatisticsGrid'>
          <div className='StatisticsTable'>
            <div className='CountryBlock'>
              <div className='TextContainer'>
                <h2>
                  <strong> Flag </strong><br />
                  <span className="material-icons">{"flag"}</span>
                </h2>
              </div>
              {flagUrl && <img className='Flag' src={flagUrl} alt={`Flag of ${country}`} />}
            </div>

            {hasExchangeRateToEur && (
              <div className='CurrencyBlock'>
                <div className='TextContainer'>
                  <h2>
                    <strong>Currency:</strong> {statisticsData[0].currency}
                  </h2>
                </div>
                <div className="CoinAnimation">
                  <CoinScene />
                </div>
                <div className='TextContainer'>
                  <h2>
                     {statisticsData[0].exchange_rate_to_eur} <strong>= 1 €</strong>
                  </h2>
                </div>
              </div>
            )}

            {hasGdp && (
              <div className='GdpBlock'>
                <div className='TextContainer'>
                  <h2>
                    <strong>GDP in dollars</strong> 
                    <span className="material-icons">{"show_chart"}</span>
                  </h2>
                </div>
                <h2 style={{ color: 'black' , alignSelf: 'center', fontSize: '14px'}}>
                {statisticsData[0].gdp} <strong>$</strong><br />
                  
                {parseFloat(statisticsData[0].gdp / 1_000_000_000).toFixed(2)} <strong>Billion $</strong>
                </h2>
              </div>
            )}

          {hasPopulation && (
              <div className='PopulationBlock'>
                <div className='TextContainer'>
                  <h2>
                    <strong>Population Number </strong> <br/>
                    <span className="material-icons">{"groups"}</span>
                  </h2>
                </div>
                <h2 style={{ color: 'black' , alignSelf: 'center', fontSize: '14px', margin: 'auto'}}>
                {statisticsData[0].population} <span className="material-icons">{"person"}</span><br />
                {parseFloat(statisticsData[0].population / 1_000_000).toFixed(2)} <strong>Million</strong>
                </h2>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsTopic;
