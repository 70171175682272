export const enableScroll = (arrayContainer) => {
    if (!arrayContainer) return;
  
    let isDown = false;
    let startX;
    let scrollLeft;
  
    const handleMouseDown = (e) => {
      isDown = true;
      arrayContainer.classList.add('active');
      startX = e.pageX - arrayContainer.offsetLeft;
      scrollLeft = arrayContainer.scrollLeft;
      e.preventDefault();
    };
  
    const handleMouseLeave = () => {
      isDown = false;
      arrayContainer.classList.remove('active');
    };
  
    const handleMouseUp = () => {
      isDown = false;
      arrayContainer.classList.remove('active');
    };
  
    const handleMouseMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - arrayContainer.offsetLeft;
      const walk = (x - startX) * 2; // Adjust the scroll speed multiplier
      arrayContainer.scrollLeft = scrollLeft - walk;
    };
  
    // Attach event listeners
    arrayContainer.addEventListener('mousedown', handleMouseDown);
    arrayContainer.addEventListener('mouseleave', handleMouseLeave);
    arrayContainer.addEventListener('mouseup', handleMouseUp);
    arrayContainer.addEventListener('mousemove', handleMouseMove);
  
    // Return a cleanup function to remove event listeners
    return () => {
      arrayContainer.removeEventListener('mousedown', handleMouseDown);
      arrayContainer.removeEventListener('mouseleave', handleMouseLeave);
      arrayContainer.removeEventListener('mouseup', handleMouseUp);
      arrayContainer.removeEventListener('mousemove', handleMouseMove);
    };
  };
  