import './HomePage.css';
import React, { useEffect, useState } from 'react';
import {fetchRandomSightseeing} from './SightseeingTopic'
import {fetchRandomFood} from './FoodTopic'
import { useNavigate } from 'react-router-dom';
import {GlobeScene} from './SpinningGlobe';
import { fetchCountryName } from  './TravelTopics';
import { Helmet } from 'react-helmet';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function HomePage() {
  const navigate = useNavigate();
  const [sightseeingData, setSightseeingData] = useState([]); // State to store search results from the database
  const [foodData, setFoodData] = useState([]); // State to store search results from the database

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchRandomSightseeing();
        setSightseeingData(data);
      } catch (error) {
        console.error('Error fetching sightseeing data:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchFoodData() {
      try {
        const data = await fetchRandomFood();
        setFoodData(data);
      } catch (error) {
        console.error('Error fetching food data:', error);
      }
    }
    fetchFoodData();
  }, []);

  return (
    <div className="HomePage">

    <Helmet>
      <title>Home Page - LivingOutOfABackpack™</title>
      <meta name="description" content="This is the home page of my React app." />
      <meta name="keywords" content="React, Home Page, SEO" />
      <meta property="og:title" content="Home Page - LivingOutOfABackpack™" />
      <meta property="og:description" content="This is the home page." />
    </Helmet>

      <div className="Title">
        Discover New Places Around The World
      </div>

      <section className='Paragraph1'>

      <div className="WebsiteDescription">
        <div className="WebsiteDescriptionList">
          <li>
          Find New Places to add to your bucket-list.
          </li>
          <li>
          Plan-out your travel agenda with our handy tools.
          </li>
        </div>
        <div className="GlobeWithButton">
          <div className="GlobeAnimation"onClick={() => navigate('/worldmap')}>
            <GlobeScene />
          </div>
          <button className="GlobeButton" onClick={() => navigate('/worldmap')}>Start</button>
        </div>
      </div>

          <div className="slider-container">
            <div className="slider">
                {Array.isArray(sightseeingData) && sightseeingData.map((item, index) => (
                  
                    <div key={index} className="slide" 
                    onClick={async () => {
                      try {
                        const countryName = await fetchCountryName(item.iso);
                        navigate(`/traveltopics/${countryName}/topics/sightseeing`);
                      } catch (error) {
                        console.error("Error fetching country name:", error);
                      }
                    }}
                    >
                      <div className='LocationLabel'>
                        <div className='LocationText'>{item.region}</div>
                      <span className="material-icons icon-region">{"location_on"}</span>
                      </div>
                      <img 
                        src={`${backendUrl}/django-dynamic/pictures/${item.images[0]}`} 
                        title={item.place ||item.activity || "Travel Spot"} 
                        alt={item.place || "Travel Spot Image"}
                      />
                    </div>
                ))}
    
            </div>
          </div>
          
        <div className="ExplanationBlock">
        <div className="ExplanationContent">
        <div className='ExplanationTitleBanner'>
          <h1>How to use:</h1> 
          </div>
              Simply use the "Travel Topics" tab to explore various categories,
              including ancient world heritage sites, exciting activities,
              or the "Food" topic for discovering renowned restaurants and must-try dishes.
        </div>
            <img className='ImageBehindText' 
              	src={`${backendUrl}/django-static/pictures/generic/finances.jpg`} 
                title={"Cliffside with beach"} />
        </div>

      </section>
      <div className='Divider'></div>
      <section className='Paragraph2'>

          <div className="slider-container">
            <div className="slider">
                {Array.isArray(foodData) && foodData.map((item, index) => (
                  
                    <div key={index} className="slide" 
                    onClick={async () => {
                      try {
                        const countryName = await fetchCountryName(item.iso);
                        navigate(`/traveltopics/${countryName}/topics/food`);
                      } catch (error) {
                        console.error("Error fetching country name:", error);
                      }
                    }}
                    >
                      <div className='LocationLabel'>
                        <div className='LocationText'>{item.dish || item.restaurant}</div>
                      <span className="material-icons icon-region">{"location_on"}</span>
                      </div>
                      <img 
                        src={`${backendUrl}/django-dynamic/pictures/${item.images[0]}`} 
                        title={item.dish || item.restaurant || "Food Post"} 
                        alt={item.place || "Food Post Image"}
                      />
                    </div>
                ))}
    
            </div>
          </div>

      <div className="ExplanationBlock">
        <div className="ExplanationContent">
          <div className='ExplanationTitleBanner'>
          <h1>Here's What We Do:</h1> 
          </div>
            Have you ever visited a country and felt unsure of what to do?
            Our website is here to assist you with all your travel needs.
            We help you discover exciting places, top attractions, and the best restaurants to visit.
        </div>
            <img className='ImageBehindText' 
              	src={`${backendUrl}/django-static/pictures/generic/public-transportation.jpg`} 
                title={"Cliffside with beach"} />
        </div>

      </section>
      

    <div className='ContactUs'>
      Contact us at: LivingOutOfABackpack.service@gmail.com,
      if you have some feedback or any other subject matter you want us to handle.
    </div>
    <div className='TradeMarkBottom'>
      LivingOutOfABackpack™
    </div>

    </div>

    
  );
}

export default HomePage;
