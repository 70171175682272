import React, { useState, useEffect } from 'react';
import {BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HomePage from './HomePage';
import WorldMap from './WorldMap';
import TravelTopics from './TravelTopics';
import FoodTopic from './FoodTopic';
import SightseeingTopic from './SightseeingTopic';
import StatisticsTopic from './StatisticsTopic';
import LatestPage from './LatestPage';
import DisclaimerAndPolicyPage from './DisclaimerAndPolicyPage';
import './App.css';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const frontendUrl = process.env.REACT_APP_FRONTEND_URL;

axios.defaults.withCredentials = true;

function getCSRFToken() {
  const csrfToken = document.cookie
      .split('; ')
      .find((row) => row.startsWith('csrftoken='))
      ?.split('=')[1];
  return csrfToken;
}

// Set up Axios globally to include CSRF token in headers
axios.interceptors.request.use(config => {
  try {
    const csrfToken = getCSRFToken();
    if (csrfToken) {
      config.headers['X-CSRFToken'] = csrfToken;
      console.log('CSRF Token (Interceptor):', csrfToken);
    }
  } catch (err) {
    console.error('Error fetching CSRF Token:', err);
  }
  return config;
}, error => {
  return Promise.reject(error);
});

function App() {
  const [activeMenu, setActiveMenu] = useState('/homepage'); // Track the active menu item
  const [selectedTopicPath, setSelectedTopicPath] = useState(null); // State for storing topicPath
  const [showChevron, setShowChevron] = useState(true); // New state for chevron visibility

  const menuItems = [
    { path: '/homepage', label: 'Home Page' },
    { path: '/worldmap', label: 'World Map' },
    { path: '/traveltopics', label: 'Travel Topics' },
    { path: '/latestpage', label: 'Latest Posts' }
  ];

  useEffect(() => {
    // Make sure CSRF token is set on the initial load (if not already set)
    axios.get(`${backendUrl}/SetCsrf/`)
      .then(response => {
        console.log('CSRF Token Set:', response.data.csrf_token);
        console.log('Cookies:', document.cookie); // Check if csrftoken is in document.cookie
      })
      .catch(error => {
        console.error('Error setting CSRF token:', error);
      });
  }, []);

  // Use useEffect to restore the active menu from localStorage
  useEffect(() => {
    const storedActiveMenu = localStorage.getItem('activeMenu');
    if (storedActiveMenu) {
      setActiveMenu(storedActiveMenu);
    }
  }, []);

  const handleMenuClick = (path) => {
    if (path === '/traveltopics') {
      setSelectedTopicPath('');  // Reset the selected topic path
    }

    setShowChevron(path !== '/disclaimerandpolicypage'); // Hide chevron for Disclaimer page

    console.log('path is'+ path);
    console.log('backend url ='+ backendUrl);
    console.log('frontend url ='+ frontendUrl);
    console.log('csrfToken ='+ getCSRFToken());
    console.log('Cookies:', document.cookie);
    setActiveMenu(path);
    localStorage.setItem('activeMenu', path); // Store the active menu in localStorage
  };

  return (
    <Router>
      <div className="App">
        <div className="Bar">
          <div className="Logo">
          <Link to={'/homepage'} onClick={() => handleMenuClick('/homepage')}>
            <img className='GlobeAnimation' 
              	src={`${frontendUrl}/static/logo.jpg`} 
                title={"Logo"} />
          </Link>
          </div>
          <Link to={'/homepage'} onClick={() => handleMenuClick('/homepage')}>
          <div className='WebsiteTitle'>
            LivingOutOfABackpack.com™
          </div>
          </Link>
        </div>
        
        <div className="page">
          <div className="side-bar">
            <div className="Menu">
              <nav className='navigation'>
                <ul className="Menu-List">
                  {menuItems.map((item, index) => (
                    <li className='Menu-Item' key={item.path}>
                      <Link
                        to={
                          item.path === '/traveltopics'
                            ? `${item.path}/${selectedTopicPath}`
                            : item.path
                        }
                        className={`button-link ${activeMenu === item.path ? 'active' : ''}`}
                        onClick={() => handleMenuClick(item.path)}
                      >
                        {item.label}
                      </Link>
                      <Link
                        to={
                          item.path === '/traveltopics'
                            ? `${item.path}/${selectedTopicPath}`
                            : item.path
                        }
                        onClick={() => handleMenuClick(item.path)}
                        className="icon-side-bar-link"
                      >
                      <span className="material-icons icon-side-bar">{getIconForMenuItem(item.path)}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
              {showChevron && (
              <span 
                className="material-icons chevron" 
                style={{transform: `translateY(${getChevronPosition(activeMenu)}%)`}}
              >
                chevron_left
              </span>)}
            </div>


            <div className='PolicyButton'>
            <Link to={'/disclaimerandpolicypage'}></Link>
                      <Link
                        to={
                          '/disclaimerandpolicypage'
                        }
                        className={`PolicyButtonLink ${activeMenu === 'disclaimerandpolicypage' ? 'active' : ''}`}
                        onClick={() => handleMenuClick('/disclaimerandpolicypage')}
                      >
                        Website Disclaimer
                      </Link>
            </div>

          </div>

          <div className="mobile-menu">
            {menuItems.map((item) => (
              <Link 
                to={
                  item.path === '/traveltopics'
                    ? `${item.path}/${selectedTopicPath}`
                    : item.path
                }
                className={`mobile-menu-item ${activeMenu === item.path ? 'active' : ''}`}
                onClick={() => handleMenuClick(item.path)}
              >
                <span className="material-icons">{getIconForMenuItem(item.path)}</span>
                <span>{item.label}</span>
              </Link>
            ))}
          </div>

          <div className="content-container">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/homepage" element={<HomePage />} />
              <Route path="/worldmap" element={<WorldMap />} />
              <Route path="/traveltopics/:topicPath" 
              element={<TravelTopics key={window.location.pathname} onTopicPathChange={setSelectedTopicPath} />}
              />
              
              <Route path="/traveltopics/:country/topics/food" element={<FoodTopic />} />
              <Route path="/traveltopics/:country/topics/sightseeing" element={<SightseeingTopic />} />
              <Route path="/traveltopics/:country/topics/statistics" element={<StatisticsTopic />} />

              <Route path="/latestpage" element={<LatestPage />} />

              <Route path="/disclaimerandpolicypage" element={<DisclaimerAndPolicyPage />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );

  // Function to get the icon based on the menu item path
  function getIconForMenuItem(path) {
    switch (path) {
      case '/homepage':
        return 'home';
      case '/worldmap':
        return 'travel_explore';
      case '/traveltopics':
        return 'topic';
      case '/latestpage':
          return 'conveyor_belt';
      default:
        return '';
    }
  }

  // Function to get the top position of the chevron dynamically
  function getChevronPosition(path) {
    const itemHeight = 100; // You can adjust this value according to the height of your menu items
    const activeItemIndex = menuItems.findIndex(item => item.path === path);
    return itemHeight * activeItemIndex;
  }
}

export default App;
