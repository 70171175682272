import './LatestPage.css';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { enableScroll } from './Scroll';
import { Helmet } from 'react-helmet';
import { fetchCountryName } from  './TravelTopics';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const LatestPage = () => {

const [sightseeingData, setSightseeingData] = useState([]); // State to store search results from the database
const [foodData, setFoodData] = useState([]); // State to store search results from the database
const arrayContainerRef = useRef(null); // Create a ref for the .array-container div
const navigate = useNavigate();
const [activeTab, setActiveTab] = useState('sightseeing');

    useEffect(() => {
    const arrayContainer = arrayContainerRef.current;

    // Call enableScroll and get the cleanup function
    const cleanupScroll = enableScroll(arrayContainer);

    // Cleanup scroll event listeners on component unmount
    return cleanupScroll;
    }, []); // Only runs once after the initial render

    useEffect(() => {
        const fetchLatestSightseeing=()=>
          {
            axios
            .get(`${backendUrl}/worlddata/sightseeing/get-sightseeing-data-latest/`)
            .then(response => {
              console.log('Latest Sightseeing Results:', response.data);
              setSightseeingData(response.data);
            })
            .catch(error => console.error('Error fetching latest posts', error));
          }
          fetchLatestSightseeing();
        }, []);

    useEffect(() => {
      const fetchLatestFood=()=>
        {
          axios
          .get(`${backendUrl}/worlddata/food/get-food-data-latest/`)
          .then(response => {
            console.log('Latest Food Results:', response.data);
            setFoodData(response.data);
          })
          .catch(error => console.error('Error fetching latest posts', error));
        }
        fetchLatestFood();
      }, []);

  return (
    <div className="LatestPage">

    <Helmet>
      <title>Latest Posts Page - LivingOutOfABackpack™</title>
      <meta name="description" content="This is the latest posts page for the website LivingOutOfABackpack." />
      <meta name="keywords" content="LivingOutOfABackpack, Latest Posts Page, Recents Posts" />
      <meta property="og:title" content="Latest Posts Page - LivingOutOfABackpack™" />
      <meta property="og:description" content="This is were the latest post are shown." />
    </Helmet>
    
      <div className="Title">
        <h2>Recent post:</h2>
      </div>

      {/* Flip Tab */}
      <div className='LatestPosts'>
        <div className="FlipTab">
              <button 
                className={`tab ${activeTab === 'sightseeing' ? 'active' : ''}`} 
                onClick={() => setActiveTab('sightseeing')}>
                Sightseeing
              </button>
              <button 
                className={`tab ${activeTab === 'food' ? 'active' : ''}`} 
                onClick={() => setActiveTab('food')}>
                Food
              </button>
        </div>

        <div className='LatestPostsContainer'>

          <div className={`LatestPostsArray ${activeTab === 'sightseeing' ? 'visible' : 'hidden'}`} ref={arrayContainerRef}>
            {Array.isArray(sightseeingData) && sightseeingData.map((post, index) => (
              <div key={index} className="array-item"  
                  onClick={async () => {
                    try {
                      const countryName = await fetchCountryName(post.iso);
                      navigate(`/traveltopics/${countryName}/topics/sightseeing`);
                    } catch (error) {
                      console.error("Error fetching country name:", error);
                    }
                  }}>
                  <div className="picture-content">
                    <img
                      src={`${backendUrl}/django-dynamic/pictures/${post.images.at(0)}`}
                      title={post.place || post.activity}
                      alt={post.place || post.activity}
                    />
                  </div>
                  <div className="block-title">{post.place}{post.activity}</div>
                  <div className="coordinates">{post.coordinates}</div>
                  <div className="TimeStamp">Created: {post.created_at} Updated: {post.updated_at}</div>
              </div>
            ))}
          </div>

          <div className={`LatestPostsArray ${activeTab === 'food' ? 'visible' : 'hidden'}`} ref={arrayContainerRef}>
            {Array.isArray(foodData) && foodData.map((post, index) => (
              <div key={index} className="array-item"
                  onClick={async () => {
                    try {
                      const countryName = await fetchCountryName(post.iso);
                      navigate(`/traveltopics/${countryName}/topics/food`);
                    } catch (error) {
                      console.error("Error fetching country name:", error);
                    }
                  }}>
                  <div className="picture-content">
                    <img
                      src={`${backendUrl}/django-dynamic/pictures/${post.images.at(0)}`}
                      title={post.dish || post.restaurant}
                      alt={post.dish || post.restaurant}
                    />
                  </div>
                  <div className="block-title">{post.dish}{post.restaurant}</div>
                  <div className="coordinates">{post.coordinates}</div>
                  <div className="TimeStamp">Created: {post.created_at} Updated: {post.updated_at}</div>
              </div>
            ))}
          </div>

        </div>
      </div>
      

    </div>

    
  );
}

export default LatestPage;
