import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ZoomableGroup, ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { useNavigate } from 'react-router-dom';  // Import useNavigate hook
import { useParams } from 'react-router-dom';  // Import useParams
import { Helmet } from 'react-helmet';
import './TravelTopics.css';
const backendUrl = process.env.REACT_APP_BACKEND_URL;
const geoJson = `${backendUrl}/django-static/world-110m.json`;


class Topic {
  constructor(name, icon, image) {
    this.name = name;
    this.icon = icon;
    this.image = image;
  }
}

const topicsArray = [
  new Topic('Sightseeing', 'hiking', 'generic/visa-requirements.jpg'),
  new Topic('Food', 'restaurant_menu', 'generic/food.jpg'),
  new Topic('Statistics', 'attach_money', 'generic/finances.jpg'),
];

export function fetchCountryIso(countryName) { //is used in other js scripts
  return axios
  .get(`${backendUrl}/worlddata/countries/${countryName}`)
  .then(response => {
    return response.data.iso;
  })
  .catch(error => console.error('Error fetching iso data by country name', error));

};

export function fetchCountryName(countryIso) { //is used in other js scripts
  return axios
  .get(`${backendUrl}/worlddata/countries/iso/${countryIso}`)
  .then(response => {
    return response.data.name;
  })
  .catch(error => console.error('Error fetching country data by iso', error));

};

function TravelTopics({ onTopicPathChange }) {
  const { topicPath } = useParams(); // Get topicPath from the URL params
  const [selectedTopic, setSelectedTopic] = useState(null); // Store clicked topic
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Control popup visibility
  const navigate = useNavigate();  // Get navigate function from useNavigate
  const [searchQuery, setSearchQuery] = useState(''); // State to store the search input
  const [searchResults, setSearchResults] = useState([]); // State to store search results from the database

  // Extract country and topic from the topicPath URL
  useEffect(() => {
    if (topicPath) {
      setSelectedTopic(topicPath);  // Set the selected topic if there's a valid topicPath
      onTopicPathChange(topicPath);  // Pass the topic path back to parent component
    } else {
      setSelectedTopic(null);  // Clear the selected topic if no valid topicPath
    }
  }, [topicPath, onTopicPathChange]);

  const handleButtonClick = (topic) => {
    setSelectedTopic(topic); // Store the clicked topic
    setIsPopupOpen(true); // Open the popup
  };

  const closePopup = () => {
    setIsPopupOpen(false); // Close the popup
    setSelectedTopic(null); // Clear the selected topic
  };

  const handleOverlayClick = (e) => {
    // Close the popup only if the click is outside the popup content
    if (e.target.classList.contains('popup-overlay')) {
      closePopup();
    }
  };

    // Function to fetch country details
    const handleClick = (geo, topic) => {
      const countryName = geo.properties.name; // Adjust based on actual field
      const topicPath = `/${countryName}/topics/${topic.name.toLowerCase()}`;

      navigate(`/traveltopics${topicPath}`); // Navigate to the new route

      if (!countryName) {
        console.error('Country name is undefined!');
        return;
      }
      onTopicPathChange(topicPath); // Update the state in App.js
    };

  // Function to handle the search query change
  const handleSearchChange = event => {
    const query = event.target.value;
    setSearchQuery(query);

    // If search query is not empty, make an API call to search countries by name
    if (query.length > 0) {
      axios
        .get(`${backendUrl}/worlddata/countries?name=${query}`)
        .then(response => {
          console.log('Search Results:', response.data); // Log to verify response data
          setSearchResults(response.data); // Update the search results with matching countries
        })
        .catch(error => console.error('Error searching countries', error));
    } else {
      setSearchResults([]); // Clear search results when search query is empty
    }
  };

  return (
    <div className="page">
          <Helmet>
            <title>Travel Topics - LivingOutOfABackpack™</title>
            <meta name="robots" content="index, follow" />
            <meta property="og:title" content="Travel Topics Page - LivingOutOfABackpack™" />
            <meta property="og:description" content={`Explore the world with the help of our tools.`} />
          </Helmet>
      <div className="grid-background">
        <div className="grid-container">
          {topicsArray.map((topic, index) => (
            <div key={index} className="grid-item">
              <button
                className="button-content"
                onClick={() => handleButtonClick(topic)} // Handle click event
              >
                <div className="picture-content">
                  <img
                    src={`${backendUrl}/django-static/pictures/${topic.image}`}
                    title={topic.name}
                    alt={topic.name}
                  />
                </div>
                <span className="material-icons icon">{topic.icon}</span>
                <div className="block-title">{topic.name}</div>
                <div className="block-text">
                  Explore {topic.name.toLowerCase()} options
                </div>
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Popup Component */}
      {isPopupOpen && (
        <div
          className="popup-overlay"
          onClick={handleOverlayClick} // Handle outside clicks
        >
          <div className='popup-content'>
            <div className='popup-header'>
              <div className='OverlayTitle'>{selectedTopic?.name}<span className="material-icons icon">{selectedTopic.icon}</span></div>

              <button className='OverlayCloseButton' onClick={closePopup}>
                  &times;
              </button>
            </div>

            <p>
                <strong> Pick a country to proceed to the {selectedTopic?.name} topic.</strong>
            </p>

          
          <div className="SearchBarContainer">
          <div
            style={{
              position: 'relative',
              width: '100%',
              background: 'white',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              borderRadius: '4px',
              zIndex: '1000',
            }}
          >
            <input className='SearchBarInput' type="text" placeholder="Search for a country" value={searchQuery} onChange={handleSearchChange}/>
            {searchQuery.length > 0 && (
              <div className='SearchBarResults'>
                <ul className='SearchBarResultsList'>
                  {searchResults.length > 0 ? (
                    searchResults.map(country => (
                      <li className='SearchBarResultsItem' key={country.iso} onClick={() =>  navigate(`/traveltopics/${country.name}/topics/${selectedTopic?.name}`)}>
                        {country.name}
                      </li>
                    ))
                  ) : (
                    <li style={{ textAlign: 'center', padding: '8px' }}>
                      No matching countries found
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>

            <ComposableMap >
              <ZoomableGroup
                center={[-80, 25]}
                zoom={6} // Zoom in on mobile
                minZoom={1} 
                maxZoom={6} 
                translateExtent={[
                  [0, -50], // Minimum X (-1000), Minimum Y (-500)
                  [900, 700],   // Maximum X (1000), Maximum Y (500)
                ]} // Restrict panning
              >
              <Geographies geography={geoJson}>
                {({ geographies }) =>
                  geographies.map(geo => (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onClick={() => handleClick(geo, selectedTopic)}
                      style={{
                        default: { 
                          fill: '#4CAF50', 
                          outline: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 0.5,
                        },
                        hover: { 
                          fill: '#ffc658', 
                          outline: 'none', // Remove outline
                          stroke: '#FFFFFF', // Add a white stroke to outline the country
                          strokeWidth: 2, // Set the width of the stroke (outline)
                          zIndex: 1000,
                        },
                        pressed: { 
                          fill: '#e6a02f', 
                          outline: 'none',
                          stroke: '#FFFFFF', // Add a white stroke when pressed
                          strokeWidth: 2, // Set the width of the pressed stroke
                        },
                      }}
                    />
                  ))
                }
              </Geographies>
              </ZoomableGroup>
            </ComposableMap>
          </div>
        </div>
      )}
    </div>
  );
}

export default TravelTopics;
