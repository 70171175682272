import React, { useRef, useEffect } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";
import * as THREE from "three";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
// Spinning Globe Component

function SpinningGlobe() {
  const globeRef = useRef();

  // Texture loader
  const texture = new THREE.TextureLoader().load(`${backendUrl}/django-static/textures/earthmap2.jpg`);

  // Rotate the globe continuously using useFrame
  useFrame(() => {
    if (globeRef.current) {
      globeRef.current.rotation.y += 0.01; // Change rotation speed here
    }
  });

  return (
    <mesh ref={globeRef} rotation={[0, 0, 0]}>
      {/* Sphere for the globe */}
      <sphereGeometry args={[4, 32, 32]} />
      <meshStandardMaterial map={texture} />
    </mesh>
  );
}

const SpinningCoin = () => {
  const coinRef = useRef(); // Reference for the coin model

  // Use the useGLTF hook to load the coin model
  const { scene } = useGLTF(`${backendUrl}/django-static/models/gold-coin.glb`);

  // Rotate the coin using useFrame
  useFrame(() => {
    if (coinRef.current) {
      coinRef.current.rotation.y += 0.02; // Rotate the coin model at a certain speed
    }
  });

  return (
    <group ref={coinRef} position={[0, -3.5, 0]}>
      {/* Render the loaded scene using <primitive> */}
      <primitive object={scene} />
    </group>
  );
};

export function CoinScene() {
  return (
    <Canvas style={{ width: "100%", height: "100%" }} camera={{ position: [2, 1, 2] }}gl={{ alpha: true }} background={null} >
      <color attach="background" args={["#f4f4f4"]} />
      {/* Lighting */}
      <ambientLight intensity={0.7} />
      <directionalLight
        position={[5, 5, 5]} // Simulating the sun's position
        intensity={5} // Sunlight intensity
        castShadow // Optional: Enable shadows from the globe
      />

      <SpinningCoin />
      <OrbitControls enableZoom={false} />
    </Canvas>
  );
}


// Stars Component
function Stars({ count = 5000 }) {
  const starPositions = new Float32Array(count * 3);
  for (let i = 0; i < count; i++) {
    starPositions[i * 3] = (Math.random() - 0.5) * 100; // X position
    starPositions[i * 3 + 1] = (Math.random() - 0.5) * 100; // Y position
    starPositions[i * 3 + 2] = (Math.random() - 0.5) * 100; // Z position
  }

  return (
    <points>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes-position"
          array={starPositions}
          count={count}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial color="white" size={0.2} />
    </points>
  );
}

export function GlobeScene() {
  return (
    <Canvas style={{ width: "100%", height: "100%",   borderRadius: "10px" }} camera={{ position: [0, 0, 10] }}gl={{ alpha: true }} background={null} >
        <color attach="background" args={["#000011"]} />
      {/* Lighting */}
      <ambientLight intensity={0.7} />
      <directionalLight
        position={[5, 5, 5]} // Simulating the sun's position
        intensity={5} // Sunlight intensity
        castShadow // Optional: Enable shadows from the globe
      />

      <Stars count={1000} />
      <SpinningGlobe />
      <OrbitControls enableZoom={false} />
    </Canvas>
  );
}
